export const fetchJson = async (locale: string, filename: string, key: string) => {
    // due to limitations of dynamic imports, the path must be exactly two levels deep
    // https://github.com/rollup/plugins/tree/master/packages/dynamic-import-vars#limitations
    const match = filename.match(/([^/]+)\/([^/]+)\/([^/]+)\.json/);
    if (!match) {
        throw new Error(`unknown json ${filename}`);
    }
    let c = (await import(`~/locale/${locale}/docs/${match[1]}/${match[2]}/${match[3]}.json`)).default;
    // terrible conventions, i know… i'm so tired…
    let explicitSign = false;
    let absolute = false;
    if (key.startsWith('@')) {
        explicitSign = true;
        key = key.substring(1);
    }
    if (key.startsWith('^')) {
        absolute = true;
        key = key.substring(1);
    }
    for (let part of key.replace(/\\\./g, '£').split('.')) {
        part = part.replace(/&#39;/g, '\'').replace(/£/g, '.');
        c = c[part];
        if (c === undefined) {
            throw `item "${part}" is undefined (file ${filename})`;
        }
    }
    if (typeof c === 'number') {
        if (absolute) {
            c = Math.abs(c);
        }
        if (explicitSign && c > 0) {
            c = `+${c}`;
        }
        // TODO make generic
        c = c.toString().replace('.', ',');
    }
    return c;
};
